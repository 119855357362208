import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";


// Check if a user is logged in before routing
export default function AuthenticatedRoute({ children }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();

  if (!isAuthenticated) {
    // include current path to redirect after the user logs in
    return <Navigate to={`/login?redirect=${pathname}${search}`} />;
  }

  return children;
}
import { Storage } from "aws-amplify";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });
  console.log(`Uploaded to s3: ${stored.key}`);

  return stored.key;
}

export async function s3Delete(fileKey) {
  const removed = await Storage.vault.remove(fileKey);
  console.log(`Deleted from s3: ${fileKey}`);

  return removed;
}
// env variables set by FrontendStack

const config = {
  // Frontend config
  MAX_ATTACHMENT_SIZE: 5000000,
  STRIPE_KEY_TEST: "pk_test_51LzJzKLKcA9yANc4ONJBVksFpzEYWlA3LoUeXOXrC86NPk5AVI0fNTs30nKmKpXncRQyA8UbIF5hHa2ByKkZWJH200XrtKGBKY",
  STRIPE_KEY_LIVE: "pk_live_51LzJzKLKcA9yANc4kCrRsY3ZCbljfUqX0DC91gIhh9XaRG66ijduHDcRoUQTE9hyX3kFflMwS1lzJHeLth51GeKT00bM35IfND",

  // Backend config
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  apiGateway: {
   REGION: process.env.REACT_APP_REGION,
   URL: process.env.REACT_APP_API_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
};
  
  export default config;
// Handler for error catching

export function onError(error) {
    let message = error.toString();
  
    // Auth errors have a different format
    if (!(error instanceof Error) && error.message) {
      message = error.message;
    }
  
    alert(message);
}